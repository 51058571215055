<template>
    <div>
        <h1>Invoice #... {todo}</h1>
    </div>
</template>

<script>
    export default {
        name: 'ClientInvoice',
    };
</script>

<style scoped></style>
