import { render, staticRenderFns } from "./ClientInvoice.vue?vue&type=template&id=39ec3196&scoped=true&"
import script from "./ClientInvoice.vue?vue&type=script&lang=js&"
export * from "./ClientInvoice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39ec3196",
  null
  
)

export default component.exports